import React, { useState } from 'react';
import { useEnvironment, useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { Info as InfoIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Text, TextButton, IconTooltip } from 'wix-ui-tpa/cssVars';
import { Status as PointsExpirationStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { Status } from '@wix/ambassador-loyalty-v1-tier/types';

import stylesParams from '../stylesParams';
import { classes, style } from './points-card.st.css';
import { useAccount, useLoyaltyProgram, useNavigationActions, useTiersProgram, useNumberFormatter } from './hooks';
import { DataHook } from '../../../types/data-hook';
import { Experiment } from '../../../constants';

export const buildImageSrc = (path: string) => `https://static.wixstatic.com/${path}`;

const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

export const PointsCard: React.FC = () => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const [showPointsExpirationTooltip, setShowPointsExpirationTooltip] = useState(false);
  const { program } = useLoyaltyProgram();
  const { programSettings } = useTiersProgram();
  const styles = useStyles();
  const { account } = useAccount();
  const { navigateToLoyaltyPage } = useNavigationActions();
  const { isMobile } = useEnvironment();
  const { formatNumber } = useNumberFormatter();
  const totalPointsTotalNumberFont = styles.get(stylesParams.totalPointsTotalNumberFont);
  const iconWidth = Math.max(30, (totalPointsTotalNumberFont.size || 0) * 0.8);
  const iconUrl = program.pointDefinition?.icon?.url;
  const showTiers = programSettings?.status === Status.ACTIVE;
  const pointsName = program.pointDefinition?.customName;
  const showPointsExpiration =
    experiments.enabled(Experiment.PointsExpiration) &&
    program.pointsExpiration?.status === PointsExpirationStatus.ENABLED &&
    !!account.pointsExpirationDate &&
    !!account.pointsExpirationAmount;

  const renderPointsExpirationStatus = (): string => {
    const expirationDate = new Date(account.pointsExpirationDate!);
    const daysRemaining = Math.round(Math.abs((Date.now() - expirationDate.getTime()) / MILLISECONDS_IN_ONE_DAY));

    let dateKey = 'date';
    if (daysRemaining <= 0) {
      dateKey = 'today';
    } else if (daysRemaining <= 30) {
      dateKey = 'days';
    }

    const pointsAmountKey = account.pointsBalance === account.pointsExpirationAmount ? 'all' : 'some';
    const pointsNameKey = pointsName ? 'custom' : 'default';

    return t(`points-expiration.status.${dateKey}.${pointsAmountKey}.${pointsNameKey}`, {
      points: account.pointsExpirationAmount,
      pointsName,
      days: daysRemaining,
      date: expirationDate.toLocaleDateString(),
    });
  };

  return (
    <div data-hook={DataHook.PointsCard} className={style(classes.root, { showTiers, isMobile })}>
      <div className={classes.contentContainer}>
        {showTiers && (
          <Text data-hook={DataHook.PointsCardDescription} className={classes.description}>
            {t(pointsName ? 'app.points-card.available-points-custom' : 'app.points-card.available-points', {
              pointsName,
            })}
          </Text>
        )}
        <div data-hook={DataHook.PointsCardPointsBalance} className={classes.pointsBalance}>
          {iconUrl ? (
            <div className={classes.icon}>
              <img width={iconWidth} src={buildImageSrc(iconUrl)} alt="Icon" />
            </div>
          ) : null}
          <Text className={classes.points}>{formatNumber(account.pointsBalance ?? 0)}</Text>
        </div>
        {!showTiers && (
          <Text data-hook={DataHook.PointsCardDescription} className={classes.description}>
            {t(pointsName ? 'app.points-card.total-points-earned-custom' : 'app.points-card.total-points-earned', {
              pointsName,
            })}
          </Text>
        )}
        {showPointsExpiration && (
          <div className={classes.pointsExpiration}>
            <Text className={classes.pointsExpirationText}>{renderPointsExpirationStatus()}</Text>{' '}
            <IconTooltip
              className={classes.pointsExpirationIcon}
              message={t('points-expiration.tooltip', { months: program.pointsExpiration?.monthsOfInactivity })}
              showTooltip={showPointsExpirationTooltip}
              onMouseEnter={() => setShowPointsExpirationTooltip(true)}
              onMouseLeave={() => setShowPointsExpirationTooltip(false)}
              icon={<InfoIcon />}
            />
          </div>
        )}
        <div className={classes.cta} data-hook={DataHook.ButtonLearnMoreWrapper}>
          <TextButton
            data-hook={DataHook.ButtonLearnMore}
            className={classes.linkToLoyalty}
            onClick={() => navigateToLoyaltyPage()}
          >
            {pointsName
              ? t('app.points-card.learn-how-to-earn-custom', { pointsName })
              : t('app.points-card.learn-how-to-earn')}
          </TextButton>
        </div>
      </div>
    </div>
  );
};
